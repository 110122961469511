<template>
  <div>
    <div class="d-flex align-items-center justify-content-center border-bottom">
      <IconButton
        @click="prevMonth"
        :icon="'chevron-left'"
        :class="isLoading ? 'is-busy' : ''"
        class="btn"
      ></IconButton>

      <div
        @click="resetMonth"
        :class="isLoading ? 'is-busy' : ''"
        class="mx-3 text-uppercase"
        role="button"
      >{{ selectedDate.format('MMMM YYYY') }}</div>

      <IconButton
        @click="nextMonth"
        :icon="'chevron-right'"
        :class="isLoading ? 'is-busy' : ''"
        class="btn"
      ></IconButton>
    </div>

    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error"></ErrorMessage>

    <div v-if="!isLoading" v-touch:swipe.left="nextMonth" v-touch:swipe.right="prevMonth">
      <div
        v-for="(group, groupIdx) in groupedSessions"
        :key="`key-${group.dtString}`"
        :class="groupIdx > 0 ? 'border-top' : ''"
        class="py-3"
      >
        <div>{{ group.dt.format('dddd, D MMM') }}</div>

        <SessionOverview
          v-for="session in group.sessions"
          :key="`key-${session.sessionId}`"
          :session="session"
          class="my-3"
        ></SessionOverview>
      </div>

      <div v-if="!groupedSessions.length" class="text-center mx-0 my-5 py-5">
        No sessions in {{ selectedDate.format('MMMM') }} {{ selectedDate.format('YYYY') }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { mapState } from 'vuex';

dayjs.extend(weekday);

const dateFormat = 'YYYY-MM-DD';

export default {
  name: 'AthletePastFeed',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    SessionOverview: () => import('@/components/SessionOverview'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('feed', ['feedItems']),
    groupedSessions() {
      const bucket = [];
      const today = dayjs();
      this.feedItems.forEach((session) => {
        const dt = dayjs(session.date);
        if (dt.isBefore(today, 'day')) {
          const dtString = dt.format(dateFormat);
          const day = bucket.find((b) => b.dtString === dtString);
          if (day) {
            day.sessions.push(session);
          } else {
            bucket.push({
              dt,
              dtString,
              sessions: [session],
            });
          }
        }
      });
      return bucket;
    },
    month() {
      return Number(this.selectedDate.format('M'));
    },
    year() {
      return Number(this.selectedDate.format('YYYY'));
    },
    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },
    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((_, index) => {
        const { month, year } = this;
        return dayjs(`${year}-${month}-${index + 1}`);
      });
    },
  },
  watch: {
    selectedDate() {
      this.$store.dispatch('feed/resetFeed');
      this.fetchFeed();
    },
  },
  methods: {
    async fetchFeed() {
      try {
        this.isLoading = true;
        const dateFrom = this.currentMonthDays[0].format(dateFormat);
        const dateTo = this.currentMonthDays[this.currentMonthDays.length - 1].format(dateFormat);
        const payload = { dateFrom, dateTo, reverseOrder: true };
        this.$store.dispatch('feed/resetFeed');
        await this.$store.dispatch('feed/fetchFeed', payload);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    sessionIsEmpty(session) {
      const exercises = session.exercises.filter((exercise) => exercise.type !== 'section');
      return !exercises.length;
    },
    resetMonth() {
      this.selectedDate = dayjs();
    },
    prevMonth() {
      this.selectedDate = dayjs(this.selectedDate).subtract(1, 'month');
    },
    nextMonth() {
      this.selectedDate = dayjs(this.selectedDate).add(1, 'month');
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      selectedDate: dayjs(),
    };
  },
  created() {
    this.$store.dispatch('feed/resetFeed');
  },
  destroyed() {
    this.$store.dispatch('feed/resetFeed');
  },
  mounted() {
    this.fetchFeed();
  },
};
</script>
